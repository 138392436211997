<template>
	<Modal
		title="Withdraw Cash"
		:subTitle="subTitle"
		:form="withdrawForm"
		:showBackButton="withdrawForm === 'withdrawOptionsForm' ? false : true"
		@close="$emit('close-modal')"
		@goBack="goBack"
		@mounted="refresh"
	>
		<template v-slot:content>
			<AmountForm
				v-if="withdrawForm === 'amountForm'"
				class="tw-px-4 md:tw-px-10"
				:withdrawMethod="withdrawMethod"
				:availableCash="cashBalance"
				:minimumWithdrawal="minimumWithdrawal"
				:rate="Number(exchangeRate)"
        @close="$emit('close-modal')"
				@storeData="switchWithdrawForm"
			/>
			<WithdrawOptionsForm
				v-if="withdrawForm === 'withdrawOptionsForm'"
				class="tw-px-4 lg:tw-px-10"
				:bankDetails="bankDetails"
				:minimumWithdrawal="minimumWithdrawal"
				@storeData="switchWithdrawForm"
			/>
			<SelectCountryForm
				v-if="withdrawForm === 'selectCountryForm'"
				class="tw-px-4 lg:tw-px-10"
				@bankConnected="switchWithdrawForm"
			/>
			<WalletList
				v-if="withdrawForm === 'wallets'"
				@wallet-select="withdrawFromWallet"
			/>
			<ConfirmationForm
				v-if="withdrawForm === 'confirmationForm'"
				class="tw-px-4 lg:tw-px-10"
				:fees="withdrawFees"
				:loading="btnLoading"
				@withdraw="withdrawRequest"
			/>
		</template>
	</Modal>
</template>

<script>
import SelectCountryForm from "@/components/general/SelectCountryForm";
import Modal from "@/components/general/sidemodal/SideModal";
import WalletList from "@/components/wallet/withdraw/WalletList";
import AmountForm from "@/components/wallet/withdraw/WithdrawAmountForm";
import ConfirmationForm from "@/components/wallet/withdraw/WithdrawConfirmationForm";
import WithdrawOptionsForm from "@/components/wallet/withdraw/WithdrawOptionsForm";
import api from "@/utils/api.js";
import responseHandler from "@/utils/responseHandler.js";
import { getItem, removeItem } from "@/utils/storage.js";
import { computed, ref } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
	name: "WithdrawalModal",
	components: {
		Modal,
		AmountForm,
		WithdrawOptionsForm,
		ConfirmationForm,
		SelectCountryForm,
		WalletList,
	},
	emits: ["close-modal"],
	setup() {
		const withdrawForm = ref("");
		const withdrawFees = ref("");
		const subTitle = ref("");
		const btnLoading = ref(false);
		const swapCurrency = ref("");
		return { withdrawForm, withdrawFees, btnLoading, subTitle, swapCurrency };
	},

	provide() {
		return {
			swapCurrency: computed(() => this.swapCurrency),
		};
	},
	computed: {
		...mapState({
			withdrawMethod: (state) => state.organisationModule.withdrawMethod,
			bankDetails: (state) => state.Account.bankAccounts,
			minimumWithdrawal: (state) => state.organisationModule.minimumWithdrawal,
			exchangeRate: (state) => state.organisationModule.exchangeRate,
			currency: (state) => state.organisationModule.currency,
		}),
		...mapGetters("organisationModule", ['walletBalance']),
		cashBalance() {
			return this.walletBalance.find(
				(wallet) => wallet.currency === this.currency
			)?.balance;
		},
	},
	methods: {
		...mapActions(["showToast"]),
		...mapActions("organisationModule", ["getWalletBalance"]),
		async switchWithdrawForm(form) {
			switch (form) {
				case "amountForm":
					await this.calculateFees();
					this.withdrawForm = "confirmationForm";
					this.subTitle = "Confirmation";
					break;
				case "withdrawOptionsForm":
					this.withdrawForm = "amountForm";
					this.subTitle = "How much do you want to withdraw?";
					break;
				case "selectCountryForm":
					this.withdrawForm = "withdrawOptionsForm";
					this.subTitle = "Withdraw cash to";
					break;
				case "Add Bank":
					this.withdrawForm = "selectCountryForm";
					this.subTitle = "";
					break;
				case "Other GetEquity Wallet":
					this.withdrawForm = "wallets";
					this.subTitle = "Which wallet are you withdrawing to?";
					break;
				case "walletList":
					this.withdrawForm = "amountForm";
					this.subTitle = "How much do you want to withdraw?";
					break;
			}
		},

		async calculateFees() {
			const currency = getItem("withdrawalPayload").currency;
			const amount = getItem("withdrawalPayload").amount;
			let currencyObject;
			let percentFee;
			let minFee;
			const response = await api.withdrawFees();
			const { data } = response.data;
			const keys = Object.keys(data);
			keys.forEach((key, index) => {
				if (key === currency) {
					currencyObject = data[key];
					switch (!!currencyObject.percentage) {
						case true:
							if (this.withdrawMethod === "Trading Wallet") {
								this.withdrawFees = 0;
								return;
							}
							percentFee = Number((currencyObject.percentage / 100) * amount);
							minFee = Number(currencyObject.fee);
							minFee > percentFee
								? (this.withdrawFees = minFee)
								: (this.withdrawFees = percentFee);
							break;
						case false:
							this.withdrawFees = Number(currencyObject.fee);
							break;
					}
				}
			});
		},
		withdrawFromWallet(currency) {
			this.swapCurrency = currency;
			this.switchWithdrawForm("walletList");
		},

		async withdrawRequest(payload) {
			this.btnLoading = true;
			try {
				if (payload.bank_id) {
					const response = await api.createWithdrawalRequest(payload);
					const { message } = response.data;
					this.showToast({
						description: message,
						display: true,
						type: "success",
					});
				} else {
					const amount = { amount: payload.tokens };
					const response = await api.withdrawToTradeWallet(amount);
					const { message } = response.data;
					this.showToast({
						description: message,
						display: true,
						type: "success",
					});
				}
				await this.getWalletBalance();
				this.btnLoading = false;
				this.$emit("close-modal");
				removeItem("withdrawalPayload");
			} catch (error) {
				console.log({ error });
				responseHandler.handleError(error);
				this.btnLoading = false;
			}
		},

		refresh() {
			this.withdrawForm = "withdrawOptionsForm";
			this.subTitle = "Withdraw cash to";
		},

		goBack(form) {
			switch (form) {
				case "amountForm":
        if (this.withdrawMethod.includes("Wallet")) {
						this.withdrawForm = "wallets";
						this.subTitle = "Which wallet are you withdrawing to?";
					} else {
						this.withdrawForm = "withdrawOptionsForm";
						this.subTitle = "Withdraw cash to";
					}
					break;
				case "confirmationForm":
					this.withdrawForm = "amountForm";
					this.subTitle = "How much do you want to withdraw?";
					break;
				case "Other GetEquity Wallet":
					this.withdrawForm = "withdrawOptionsForm";
					this.subTitle = "Withdraw cash to";
					break;
				case "wallets":
					this.withdrawForm = "withdrawOptionsForm";
					this.subTitle = "Withdraw cash to";
					break;
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
