<template>
  <div id="select-bank" class="">
    <label for="bank"></label>
    <div
      class="tw-relative tw-flex tw-items-center tw-justify-evenly tw-rounded tw-bg-gray-lightest tw-border hover:tw-border-primary tw-border-gray-dark"
    >
      <img
        class="tw-w-6 tw-absolute tw-top-4 tw-left-5"
        src="@/assets/img/bank-icon.svg"
        alt="bank"
      />
      <select
        name="bank"
        id="bank"
        class="tw-flex tw-flex-col tw-border-transparent tw-w-full tw-h-28 xs:tw-h-28 tw-cursor-pointer tw-p-4 tw-ml-10 tw-z-10"
        v-model="accountNumber"
        @input="setBankDetails"
        required
      >
        <option value="" disabled>Select Bank</option>
        <option
          :value="bank.number"
          v-for="(bank, index) in bankDetails"
          :key="index"
        >
          {{ bank.name }} {{ bank.number }}
        </option>
      </select>
      <p
        v-if="accountNumber === ''"
        class="tw-absolute tw-left-6 tw-px-8 tw-mt-8 tw-text-sm tw-text-purple-light"
      >
        Withdraw cash to a bank account you've already added
      </p>
      <p
        v-if="accountNumber !== ''"
        class="tw-absolute tw-left-6 tw-px-8 tw-mt-8 tw-text-sm tw-text-purple-light"
      >
        Account name: <span class="tw-font-semibold">{{ accountName }}</span>
        <br />
        Account number:
        <span class="tw-font-semibold">{{ accountNumber }}</span>
      </p>
      <img
        src="@/assets/img/chevron-down.svg"
        class="tw-absolute tw-right-3 tw-bottom-13"
        alt="chevron icon"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectBank",

  emits: ["set"],

  props: {
    bankDetails: { type: Array, default: () => [], required: true },
  },

  data() {
    return {
      payload: {
        bank_id: "",
        currency: "",
      },
      accountName: "",
      accountNumber: "",
    };
  },

  methods: {
    emitPayload(payload) {
      this.$emit("set", payload);
    },

    setBankDetails(event) {
      this.accountNumber = event.target.value;
      this.bankDetails.map((bank) => {
        if (bank.number === this.accountNumber) {
          this.accountName = bank.accountName;
          this.payload.bank_id = bank._id;
          this.payload.currency = bank.currency;
        }
      });
      this.emitPayload(this.payload);
    },
  },

  watch: {
    type(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.emitPayload(this.payload);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
