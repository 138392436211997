<template>
	<div id="withdraw-amount-form">
		<div
			v-if="!loading"
			class="tw-w-48 tw-text-sm tw-text-center tw-rounded tw-bg-gray-bg3 tw-mx-auto tw-mt-4 tw-p-1"
		>
			<p class="">Available Cash</p>
			<p>{{ formatAmountToDollar(availableCash, 2, currency) }}</p>
		</div>
		<template v-if="swapCurrency">
			<form
				v-if="!loading"
				class="tw-w-full tw-mt-8"
				@submit.prevent="storeData"
			>
				<div class="tw-grid tw-grid-cols-2 tw-items-center tw-mb-8">
					<div>
						<p class="tw-font-bold">Enter {{ currency }} amount</p>
						<p
							class="tw-text-sm tw-leading-[21px] tw-text-purple-light"
							v-if="currencyRate"
						>
							Rate:
							{{ formatAmountToDollar(1, 2, currency) }} =
							{{ formatAmountToDollar(currencyRate, 2, swapCurrency) }}
						</p>
					</div>

					<NumberInput
						placeHolder="Amount"
						label="amount"
						:amount="payload.amount"
						:minNumber="minimumWithdrawal"
						:maxNumber="availableCash"
						type="text"
						name="amount"
						id="amount"
						@set="setAmount"
						@validate="validate"
					/>
				</div>
				<div class="tw-grid tw-grid-cols-2 tw-items-center" v-if="currencyRate">
					<div>
						<p class="tw-font-bold">Equivalent {{ swapCurrency }} amount</p>
					</div>

					<NumberInput
						placeHolder="Amount"
						label="amount"
						:amount="swapConversion"
						:disabled="true"
						type="text"
						name="equivalent"
						id="equivalent"
					/>
				</div>
				<label
					for="fullBalance"
					class="tw-flex tw-mt-8 tw-gap-x-4 tw-cursor-pointer"
				>
					<input
						type="checkbox"
						name="fullBalance"
						id="fullBalance"
						class="tw-accent-secondary"
						v-model="fullAmount"
					/>
					<p class="">
						Withdraw available balance:
						<span class="tw-font-bold tw-text-secondary">{{
							formatAmountToDollar(availableCash, 2, currency)
						}}</span>
					</p>
				</label>
				<Btn class="tw-mt-8" :title="`Move Funds to ${swapCurrency} Wallet`" />
			</form>
		</template>
		<template v-else>
			<form
				v-if="!loading"
				class="tw-w-full tw-mt-8"
				@submit.prevent="storeData"
			>
				<div class="tw-grid tw-grid-cols-2 tw-items-center">
					<div>
						<p class="tw-font-bold">Enter {{ currency }} amount</p>
					</div>
					<NumberInput
						placeHolder="Amount"
						label="amount"
						:amount="payload.amount"
						:minNumber="minimumWithdrawal"
						:maxNumber="availableCash"
						type="text"
						name="amount"
						id="amount"
						@set="setAmount"
						@validate="validate"
					/>
				</div>
				<label
					for="fullBalance"
					class="tw-flex tw-mt-8 tw-gap-x-4 tw-cursor-pointer"
				>
					<input
						type="checkbox"
						name="fullBalance"
						id="fullBalance"
						class="tw-accent-secondary"
						v-model="fullAmount"
					/>
					<p class="">
						Withdraw available balance:
						<span class="tw-font-bold tw-text-secondary">{{
							formatAmountToDollar(availableCash, 2, currency)
						}}</span>
					</p>
				</label>
				<Btn class="tw-mt-8" title="Continue" />
			</form>
		</template>
		<SmallLoader v-if="loading" />
	</div>
</template>

<script>
import Btn from "@/components/general/BtnComponent.vue";
import NumberInput from "@/components/wallet/NumberInput.vue";
import { formatAmountToDollar } from "@/utils/helpers.js";
import { getItem, setItem } from "@/utils/storage.js";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
	name: "WithdrawAmountForm",

	emits: ["storeData", 'close'],

	components: {
		NumberInput,
		Btn,
	},

	props: {
		availableCash: { type: Number, default: () => 0, required: true },
		minimumWithdrawal: { type: Number, default: () => 0, required: true },
		rate: { type: Number, default: () => 0, required: true },
		withdrawMethod: { type: String, default: () => "" },
	},

	inject: ["swapCurrency"],

	data() {
		return {
			validInput: {
				dollarAmount: false,
				nairaAmount: false,
			},
			payload: {
				amount: "",
			},
			withdrawPayload: {},
			dollarStatus: true,
			nairaStatus: true,
			fullAmount: false,
		};
	},

	computed: {
		...mapState({
			loading: (state) => state.smallLoading,
			currency: (state) => state.organisationModule.currency,
		}),
		...mapGetters("organisationModule", ["currencyRate"]),
		swapConversion() {
			if (this.swapCurrency) {
				return String(this.payload.amount * Number(this.currencyRate));
			}
			return "0";
		},
	},
	watch: {
		fullAmount(val) {
			if (val) {
				this.payload.amount = String(this.availableCash);
			}
		},
	},

	mounted() {
		this.getMinimumWithdrawal();
		this.withdrawPayload = getItem("withdrawalPayload");

		if (this.withdrawMethod.includes("Wallet")) {
			this.getRate();
		}
	},

	methods: {
		...mapActions("organisationModule", [
			"getMinimumWithdrawal",
			"convertCurrency",
			"convertWallet",
			"getWalletBalance",
		]),
    ...mapActions(['setSmallLoading', 'showToast']),

		formatAmountToDollar,
		async getRate() {
			const exchangeQuery = {
				from: this.currency,
				to: this.swapCurrency,
				amount: 1,
			};
			await this.convertCurrency(exchangeQuery);
		},

		setAmount(amount) {
			this.payload.amount = amount;
		},

		validate() {},

		storeData() {
			this.withdrawPayload.tokens = this.payload.amount;
			this.withdrawPayload.amount = this.payload.amount;

			setItem("withdrawalPayload", this.withdrawPayload);
			if (this.withdrawMethod.includes("Wallet")) {
				this.fundFromWallet();
			} else {
				this.$emit("storeData", "amountForm");
			}
		},

		async fundFromWallet() {
			const payload = {
				amount: this.payload.amount,
				from: this.currency,
				to: this.swapCurrency,
			};
			this.setSmallLoading(true);
			try {
				const response = await this.convertWallet(payload);
				const { message } = response.data;
				this.showToast({
					description: message,
					display: true,
					type: "success",
				});
				await this.getWalletBalance();
				this.setSmallLoading(false);
				this.$emit("close", "Fund Wallet");
			} catch (error) {
				this.setSmallLoading(false);
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
