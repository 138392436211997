<template>
  <div>
    <div
      class="tw-bg-gray-lightest tw-rounded tw-cursor-pointer tw-p-4 tw-mt-8"
    >
      <div class="tw-flex tw-justify-between">
        <div>
          <p class="tw-text-gray">You are withdrawing</p>
          <h3 class="tw-font-bold">
            {{ formatAmountToDollar(payloadDetails.tokens, 2, "USD") }}
          </h3>
        </div>
        <div>
          <p class="tw-text-gray">Transaction Fee</p>
          <div class="tw-text-right">
            <h3 class="tw-font-bold">
              {{ charges }}
            </h3>
          </div>
        </div>
      </div>
      <div class="tw-mt-8">
        <p class="tw-text-gray">You get</p>
        <h3 class="tw-font-bold">
          {{ amount }}
        </h3>
      </div>
    </div>
    <form @submit.prevent="withdrawRequest" class="tw-w-full tw-mt-8">
      <h3 class="tw-text-lg tw-text-center tw-font-bold tw-mb-8">
        Enter your PIN to confirm
      </h3>
      <div ref="codeInput" class="tw-flex tw-justify-center tw-items-center">
        <OnboardingCodeInput
          label="Verification code"
          type="password"
          :digit="digit.firstDigit"
          :error="errorOne || {}"
          :position="1"
          @input="setDigit"
        />
        <div class="tw-mb-6">&nbsp;&#8212;&nbsp;</div>
        <OnboardingCodeInput
          label="Verification code"
          type="password"
          :digit="digit.secondDigit"
          :error="errorTwo || {}"
          :position="2"
          @input="setDigit"
        />
        <div class="tw-mb-6">&nbsp;&#8212;&nbsp;</div>
        <OnboardingCodeInput
          label="Verification code"
          type="password"
          :digit="digit.thirdDigit"
          :error="errorThree || {}"
          :position="3"
          @input="setDigit"
        />
        <div class="tw-mb-6">&nbsp;&#8212;&nbsp;</div>
        <OnboardingCodeInput
          label="Verification code"
          type="password"
          :digit="digit.forthDigit"
          :error="errorFour || {}"
          :position="4"
          @input="setDigit"
        />
      </div>
      <OnboardingBtn
        class="tw-mt-4"
        :loading="loading || isClicked"
        title="Withdraw"
      />
    </form>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import useValidate from "@vuelidate/core";
  import { required, maxLength } from "@vuelidate/validators";
  import OnboardingCodeInput from "@/components/onboarding/OnboardingCodeInput.vue";
  import OnboardingBtn from "@/components/general/BtnComponent.vue";
  import { formatAmountToDollar } from "@/utils/helpers.js";
  import { getItem } from "@/utils/storage.js";

  export default {
    name: "WithdrawConfirmationForm",

    components: {
      OnboardingCodeInput,
      OnboardingBtn,
    },

    emits: ["withdraw"],

    props: {
      loading: { type: Boolean, default: () => false, required: true },
      fees: { type: Number, default: () => 0, required: true },
    },

    data() {
      return {
        v$: useValidate(),
        fields: [],
        fieldNum: 0,
        payload: { pin: "" },
        payloadDetails: {},
        charges: "",
        amount: "",
        digit: {
          firstDigit: "",
          secondDigit: "",
          thirdDigit: "",
          forthDigit: "",
        },
        isClicked: false,
        errorOne: {},
        errorTwo: {},
        errorThree: {},
        errorFour: {},
      };
    },

    validations() {
      return {
        digit: {
          firstDigit: { required, maxLength: maxLength(1) },
          secondDigit: { required, maxLength: maxLength(1) },
          thirdDigit: { required, maxLength: maxLength(1) },
          forthDigit: { required, maxLength: maxLength(1) },
        },
      };
    },

    mounted() {
      if (!getItem("withdrawalPayload")) return;
      this.payloadDetails = getItem("withdrawalPayload");
      const currency = this.payloadDetails?.currency;
      this.amount = formatAmountToDollar(
        this.payloadDetails?.amount - this.fees,
        2,
        currency
      );
      this.charges = formatAmountToDollar(this.fees, 2, currency);
      this.fields = this.$refs.codeInput.querySelectorAll("input");
      this.fieldNum = this.fields.length;
    },

    methods: {
      ...mapActions("organisationModule", ["verifyPin"]),

      formatAmountToDollar,

      async withdrawRequest() {
        this.isClicked = true;
        const response = await this.verifyPin(this.payload);
        if (response) {
          // this.payloadDetails.currency !== "NGN"
          //   ? (this.payloadDetails.amount = this.payloadDetails.tokens)
          //   : "";
          this.$emit("withdraw", this.payloadDetails);
        }
        this.isClicked = false;
      },

      setDigit(payload) {
        const keyCode = payload.$event ? payload.$event.inputType : null;
        switch (payload.position) {
          case 1:
            this.digit.firstDigit = payload.digit;
            this.v$.digit.firstDigit.$touch();
            this.errorOne = this.v$.$errors[0];
            this.concatDigits();
            if (keyCode === "deleteContentBackward") {
              return;
            } else {
              this.fields[payload.position].focus();
            }
            break;
          case 2:
            this.digit.secondDigit = payload.digit;
            this.v$.digit.secondDigit.$touch();
            this.errorTwo = this.v$.$errors[0];
            this.concatDigits();
            if (keyCode === "deleteContentBackward") {
              this.fields[payload.position - 2].focus();
              this.fields[payload.position - 2].select();
            } else {
              this.fields[payload.position].focus();
            }
            break;
          case 3:
            this.digit.thirdDigit = payload.digit;
            this.v$.digit.thirdDigit.$touch();
            this.errorThree = this.v$.$errors[0];
            this.concatDigits();
            if (keyCode === "deleteContentBackward") {
              this.fields[payload.position - 2].focus();
              this.fields[payload.position - 2].select();
            } else {
              this.fields[payload.position].focus();
            }
            break;
          case 4:
            this.digit.forthDigit = payload.digit;
            this.v$.digit.forthDigit.$touch();
            this.errorFour = this.v$.$errors[0];
            this.concatDigits();
            if (keyCode === "deleteContentBackward") {
              this.fields[payload.position - 2].focus();
              this.fields[payload.position - 2].select();
            }
            break;

          default:
            break;
        }
      },

      concatDigits() {
        this.payload.pin = this.digit.firstDigit
          .concat(this.digit.secondDigit)
          .concat(this.digit.thirdDigit)
          .concat(this.digit.forthDigit);
      },
    },
  };
</script>

<style lang="scss" scoped></style>
