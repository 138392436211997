<template>
	<div class="tw-w-full tw-mt-6 tw-max-w-[336px] tw-mx-auto">
		<div
			class="tw-w-full tw-flex tw-items-start tw-px-[14px] tw-py-3 tw-rounded tw-border tw-border-pastel3 tw-gap-x-2 tw-mb-4 tw-cursor-pointer hover:tw-border-primary"
			v-for="wallet in filteredWallets"
			:key="wallet.currency"
			@click="$emit('wallet-select', wallet.currency)"
		>
			<CurrencyFlag :currency="wallet.currency" />
			<div>
				<p
					class="tw-text-secondary tw-text-base tw-leading-[21px] !tw-font-CircularStd-medium"
				>
					{{ wallet.currency }} Wallet
				</p>
			</div>
		</div>
		<button
			class="tw-rounded tw-primary tw-w-full tw-bg-primary tw-text-white tw-px-4 tw-py-2 tw-mt-8 tw-block tw-ml-auto tw-h-[50px] tw-leading-[22.4px]"
			@click="$emit('wallet-select', currency)"
		>
			Continue
		</button>
	</div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import CurrencyFlag from "../CurrencyFlag.vue";

defineEmits(["wallet-select"]);

const store = useStore();

const walletBalance = computed(
	() => store.getters["organisationModule/walletBalance"]
);
const currency = computed(() => store.state.organisationModule.currency);
const filteredWallets = computed(() => {
	return walletBalance.value?.filter(
		(wallet) => wallet.currency !== currency.value
	);
});
</script>

<style lang="scss" scoped></style>
