<template>
	<div>
		<div v-if="!loading">
			<OptionsCard
				header="Other GetEquity Wallet"
				:text="`Move your funds from ${currency} wallet to another wallet`"
				@clicked="clicked"
			/>
			<SelectBank
				v-if="currencyBankDetails.length !== 0"
				class="tw-mt-8"
				:bankDetails="currencyBankDetails"
				@set="storeData"
			/>
			<OptionsCard
				header="Add Bank"
				text="Add a new bank account to withdraw your funds to"
				@clicked="clicked"
			/>
		</div>
		<SmallLoader v-if="loading" />
	</div>
</template>

<script>
import OptionsCard from "@/components/wallet/OptionsCard.vue";
import SelectBank from "@/components/wallet/SelectBank.vue";
import { setItem } from "@/utils/storage.js";
import { mapActions, mapState } from "vuex";

export default {
	name: "WithdrawOptionsForm",

	components: {
		SelectBank,
		OptionsCard,
	},

	props: {
		bankDetails: { type: Array, default: () => [], required: true },
		minimumWithdrawal: { type: Number, default: () => 0, required: true },
	},

	data() {
		return {
			payload: {
				amount: "",
				bank_id: "",
				currency: "",
				tokens: "",
			},
		};
	},

	computed: {
		...mapState({
			currency: (state) => state.organisationModule.currency,
			loading: (state) => state.smallLoading,
		}),
		currencyBankDetails() {
			return this.bankDetails.filter((bank) => bank.currency === this.currency);
		},
	},

	methods: {
		...mapActions("organisationModule", [
			"setCurrency",
			"setWithdrawMethod",
			"getExchangeRate",
		]),

		async clicked(card) {
			switch (card) {
				case "Other GetEquity Wallet":
					this.payload.currency = this.currency;
					setItem("withdrawalPayload", this.payload);
					this.setWithdrawMethod(card);
					this.$emit("storeData", card);
					break;

				case "Add Bank":
					this.$emit("storeData", card);
					break;

				default:
					break;
			}
		},

		async storeData(payload) {
			this.payload.bank_id = payload.bank_id;
			this.payload.currency = payload.currency;
			this.setCurrency(this.payload.currency);
			setItem("withdrawalPayload", this.payload);
			const exchangeQuery = { from: this.payload.currency, to: "USD" };
			const response = await this.getExchangeRate(exchangeQuery);
			if (response) {
				this.$emit("storeData", "withdrawOptionsForm");
				this.setWithdrawMethod("Bank");
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
