<template>
	<div id="my-tokens">
		<div class="tw-w-full tw-overflow-auto">
			<table class="table tw-w-full">
				<thead v-if="tableRows.length !== 0" class="header">
					<tr class="header-row">
						<th
							class="header-data tw-font-normal tw-py-6"
							ref="tableHead"
							:style="gridStyling"
						>
							<div
								class="tw-justify-self-start tw-text-sm tw-text-[#323E67]"
								v-for="(header, index) in tableHeaders"
								:key="'header' + index"
							>
								{{ header }}
							</div>
						</th>
					</tr>
				</thead>
				<tbody class="table-body">
					<tr class="body-row">
						<td class="body-data tw-min-h-full" ref="tableRow">
							<div
								class="data tw-py-6 tw-border-t tw-border-gray-light hover:tw-bg-gray-lightest tw-cursor-pointer"
								v-for="(details, index) in tableRows"
								:key="'row' + index"
								:style="gridStyling"
								@click="gotoTokenPage(details)"
							>
								<div
									class="tw-self-center"
									v-for="(detail, index) in details"
									:key="'column' + index"
								>
									<p v-html="detail" class="tw-text-sm"></p>
								</div>
							</div>
							<EmptyState v-if="tableRows.length === 0 && !loading">
								<template v-slot:message>
									<p class="message tw-text-center tw-text-gray tw-my-3">
										You have no shares.
									</p>
								</template>
							</EmptyState>
							<SmallLoader v-if="loading && table === 'myTokens'" />
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="tw-flex tw-justify-end tw-mr-2">
			<Pagination
				:totalPage="pages"
				:currentPage="page"
				:maxVisible="5"
				@pageChange="gotoPage"
			/>
		</div>
	</div>
</template>

<script>
import Pagination from "@/components/navigation/Pagination.vue";
import EmptyState from "@/components/notification/EmptyState.vue";
import { formatAmountToDollar } from "@/utils/helpers.js";
import { mapActions, mapState } from "vuex";

export default {
	name: "TokensTable",

	components: { EmptyState, Pagination },

	props: {
		table: { type: String, default: () => "" },
	},

	data() {
		return {
			loading: false,
			tableHeaders: ["", "Company", "Total token", "Current price", "Value"],
			tableRows: [],
		};
	},

	computed: {
		...mapState({
			tokens: (state) => state.Asset.tokens,
			page: (state) => state.Asset.tokenPage,
			pages: (state) => state.Asset.tokenPages,
		}),
		gridStyling() {
			return {
				"grid-template-columns": `${60}px repeat(${4}, ${200}px)`,
			};
		},
	},

	created() {
		this.getOrgTokens();
	},

	methods: {
		...mapActions("Asset", ["getTokens"]),

		gotoTokenPage(details) {
			const id = details.id.split(">")[1].split("<")[0];
			const symbol = details.symbol.split(">")[1].split("<")[0];
			this.$router.push({ name: "TokenTrade", params: { symbol, id } });
		},

		async getOrgTokens() {
			this.loading = true;
			const query = { page: 1, limit: 10 };
			await this.getTokens(query);
			this.loading = false;
		},

		updateTableRow(tokens) {
			this.tableRows = tokens.map((token) => {
				let balance = token.balance.toFixed(2);
				return {
					image: `<img class="tw-w-16 tw-rounded-full tw-mx-auto" src=${token?.image} alt="token"/>`,
					company: `<p class="tw-h-px tw-font-CircularStd-medium tw-font-medium tw-text-secondary">${token?.name}</p><br />
          <p class="tw-relative tw-z-10 tw-bg-white tw-text-sm tw-text-[#949DBC]">${token.creator?.industry}</p>`,
					TotalToken: `${token.balance ? balance : 0}<span class="tw-ml-1">${
						token.symbol
					}</span>`,
					currentPrice: `${formatAmountToDollar(
						token?.price.exchange,
						2,
						token.currency || "USD"
					)}`,
					value: `${formatAmountToDollar(
						token.usd_value,
						2,
						token.currency || "USD"
					)}`,
					id: `<span class="tw-hidden">${token?._id}</span>`,
					symbol: `<span class="tw-hidden">${token?.symbol}</span>`,
				};
			});
		},

		async gotoPage(tablePage) {
			this.loading = true;
			this.tableRows = [];
			const query = { page: tablePage, limit: 10 };
			await this.getTokens(query);
			this.loading = false;
		},
	},

	watch: {
		tokens(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.updateTableRow(this.tokens);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
#my-tokens {
	@include fadeIn;

	.table {
		.header {
			.header-row {
				.header-data {
					display: grid;
					column-gap: 2rem;
				}
			}
		}
		.table-body {
			.body-row {
				.body-data {
					.data {
						display: grid;
						column-gap: 2rem;
						font-size: 0.7rem;
					}
				}
			}
		}
	}
}
</style>
