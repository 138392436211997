<template>
  <div id="options-card" @click="cardClicked">
    <div
      class="tw-bg-gray-lightest tw-border tw-border-gray-dark hover:tw-border-primary tw-rounded tw-cursor-pointer tw-p-4 tw-mt-8"
    >
      <div class="tw-flex tw-items-center">
        <img
          v-if="header === 'Other GetEquity Wallet'"
          class="tw-w-6 tw-mr-4"
          src="@/assets/img/blue-wallet-icon.svg"
          alt="plus"
        />
        <img
          v-if="header === 'Add Bank'"
          class="tw-w-6 tw-mr-4"
          src="@/assets/img/blue-plus-icon.svg"
          alt="plus"
        />
        <h3>{{ header }}</h3>
      </div>
      <p class="tw-text-sm tw-text-purple-light tw-mt-2 tw-ml-10">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "OptionsCard",

  emits: ["clicked"],

  props: {
    header: { type: String, default: () => "", required: true },
    text: { type: String, default: () => "", required: true },
  },

  methods: {
    cardClicked() {
      this.$emit("clicked", this.header);
    },
  },
};
</script>

<style lang="scss" scoped></style>
