<template>
	<div id="wallet">
		<Loader v-if="loading" />

		<div v-else class="container tw-px-4">
			<h3
				class="tw-text-[22px] tw-font-bold tw-pb-4 lg:tw-px-4 tw-text-black-dark"
			>
				Wallet
			</h3>
      <div
        class="tw-relative md:tw-grid md:tw-grid-flow-col md:tw-grid-cols-3 md:tw-gap-x-4 tw-mt-4"
      >
        <WalletCard
          v-for="wallet in walletBalance"
          :key="wallet.id"
          :wallet="wallet"
          @fund-wallet="showFundModal = !showFundModal"
          @withdraw="showWithdrawModal = true"
        />
      </div>
			<!-- <div class="md:tw-flex md:tw-justify-between tw-mb-2 lg:tw-px-4">
				<div
					class="tw-bg-white tw-rounded-[4px] tw-border tw-border-[#F1F3F6] tw-p-8 tw-flex tw-w-full tw-gap-x-4"
					v-if="bankDetails && bankDetails.length > 0"
				>
					<img
						:src="selectedBank.image"
						@error="setImage"
						:alt="selectedBank.name"
						class="tw-w-12 tw-h-12"
					/>
					<div class="tw-w-full">
						<div class="tw-mb-1 md:tw-flex tw-justify-between">
							<p class="tw-text-secondary tw-text-xl tw-flex-1">
								{{ selectedBank.name }}
							</p>
							<router-link class="tw-text-primary" :to="{ name: 'Bank' }"
								>See all</router-link
							>
						</div>
						<p class="tw-text-base tw-text-[#323E67]">
							{{ selectedBank.number }}
						</p>
						<p class="tw-text-base tw-text-[#323E67]">
							{{ selectedBank.accountName }}
						</p>
					</div>
				</div>
			</div> -->
			<div
				class="tw-bg-white tw-rounded-lg lg:tw-mx-4 tw-px-4 tw-py-6 tw-my-14"
			>
				<TableHeader
					v-if="products.includes('INVEST')"
					:options="options"
					label="options"
					:tab="currentTab"
					@emitOption="switchTab"
				/>
				<TransactionTable
					v-if="currentTab === 'Transactions'"
					table="transactions"
				/>
				<TokensTable v-else-if="currentTab === 'Shares'" table="myTokens" />
			</div>
			<WithdrawModal
				v-if="showWithdrawModal"
				@close-modal="showWithdrawModal = false"
			/>
			<FundModal v-if="showFundModal" @close-modal="closeModal" />
		</div>
	</div>
</template>

<script>
import EmptyState from "@/components/notification/EmptyState";
import TableHeader from "@/components/tables/TableHeader";
import TokensTable from "@/components/tables/TokensTable";
import TransactionTable from "@/components/tables/TransactionTable";
import WalletCard from "@/components/wallet/WalletCard";
import FundModal from "@/components/wallet/fund/FundModal.vue";
import ConfirmationForm from "@/components/wallet/withdraw/WithdrawConfirmationForm";
import WithdrawModal from "@/components/wallet/withdraw/WithdrawModal";
import { useFundNotification } from "@/composables/notifyUserFunding";
import addBankApi from "@/utils/addBankApi";
import api from "@/utils/api.js";
import { formatAmountToDollar, isNumber } from "@/utils/helpers.js";
import responseHandler from "@/utils/responseHandler.js";
import { getItem, removeItem } from "@/utils/storage.js";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
	name: "Wallet",

	components: {
		WithdrawModal,
		FundModal,
		WalletCard,
		TableHeader,
		TransactionTable,
		TokensTable,
		EmptyState,
		ConfirmationForm,
	},

	setup() {
		const { checkFundingStatus } = useFundNotification();
		return { checkFundingStatus };
	},

	data() {
		return {
			loading: false,
			showWithdrawModal: false,
			showFundModal: false,
			btnLoading: false,
			isAllowed: false,
			subTitle: "",
			withdrawForm: "",
			fundSubTitle: "",
			fundForm: "",
			withdrawFees: 0,
			currentTab: "Transactions",
		};
	},

	computed: {
		...mapState({
			minimumWithdrawal: (state) => state.organisationModule.minimumWithdrawal,
			entityCard: (state) => state.organisationModule.entityCard,
			bankDetails: (state) => state.Account.bankAccounts,
			fundMethod: (state) => state.organisationModule.fundMethod,
			withdrawMethod: (state) => state.organisationModule.withdrawMethod,
			currency: (state) => state.organisationModule.currency,
			exchangeRate: (state) => state.organisationModule.exchangeRate,
		}),
		...mapGetters("organisationModule", ["products", 'walletBalance']),
		selectedBank() {
			return this.bankDetails[0];
		},
		options() {
			let options = null;
			const hasInvest = this.products.includes("INVEST");
			if (hasInvest) {
				options = ["Transactions", "Shares"];
			} else {
				options = ["Transactions"];
			}
			return options;
		},
	},

	created() {
		this.getWithdrawalDetails();
	},

	watch: {
		showWithdrawModal(val) {
			if (val) {
				this.fetchBankAccounts();
			}
		},
	},

	mounted() {
		removeItem("fundPayload");
		const query = this.$route.query;
		if (query.ref) {
			const data = {
				code: query.ref,
			};
			this.finalizeBankConnection("nordigen", data);
			return;
		}
		if (query.code && query.state) {
			let redirectUri = `${window.location.origin}/dashboard/wallet`;
			const verifier = getItem(query.state);
			const data = {
				verifier,
				code: query.code,
				redirectUri,
			};
			this.finalizeBankConnection("stitch", data);
			return;
		}
		this.checkFundingStatus();
	},

	methods: {
		...mapActions("organisationModule", [
			"getWalletBalance",
			"generateVirtualAccount",
			"getEntityCard",
		]),
		...mapActions("Account", ["fetchBankAccounts"]),
		...mapActions(["showToast", "setLoading"]),

		formatAmountToDollar,
		isNumber,
		setImage(e) {
			e.target.src =
				"https://res.cloudinary.com/djalafcj9/image/upload/v1668692227/getequityV2/fou-info_wiqluf.svg";
		},
		switchTab(tab) {
			this.currentTab = tab;
		},

		async getWithdrawalDetails(page) {
			this.loading = true;
			let bankDetails = this.fetchBankAccounts();
			let walletBalance = this.getWalletBalance();
			let entityCards = this.getEntityCard();
			try {
				await Promise.all([bankDetails, walletBalance, entityCards]);
				this.loading = false;
			} catch (error) {
				responseHandler.handleError(error);
				this.loading = false;
			}
		},

		async withdrawRequest(payload) {
			this.loading = true;
			try {
				if (payload.bank_id) {
					const response = await api.createWithdrawalRequest(payload);
					const { message } = response.data;
					this.showToast({
						description: message,
						display: true,
						type: "success",
					});
				} else {
					const amount = { amount: payload.tokens };
					const response = await api.withdrawToTradeWallet(amount);
					const { message } = response.data;
					this.showToast({
						description: message,
						display: true,
						type: "success",
					});
				}
				await this.getWithdrawalDetails();
				this.loading = false;
				this.showWithdrawModal = false;
				removeItem("withdrawalPayload");
			} catch (error) {
				responseHandler.handleError(error);
				this.loading = false;
			}
		},

		async switchWithdrawForm(form) {
			switch (form) {
				case "Fund with Cash":
					this.fundForm = "SelectCurrencyForm";
					this.fundSubTitle = "Select your preferred currency.";
					this.fundOptions = "Fund with Cash";
					break;
				case "selectCurrencyForm":
					const payload = {
						currency: this.currency,
						provider: "flutterwave",
					};
					if (this.fundMethod === "virtual") {
						const response = await this.generateVirtualAccount(payload);
						if (response) {
							this.fundForm = "DedicatedVirtualAccountForm";
							this.fundSubTitle =
								this.currency === "NGN"
									? "Make a direct transfer to this account to fund your wallet. Transactions may take 24 hours to complete."
									: "Make a direct transfer to this account to fund your wallet. Transactions may take 3-5 business days to complete.";
						}
					}
					if (this.fundMethod === "fiat" || this.fundMethod === "crypto") {
						this.fundForm = "fundAmountForm";
						this.fundSubTitle = "How much do you want to fund?";
					}
					if (this.fundOptions === "Fund with Fintech Apps") {
						this.fundOptions = "Fund with Fintech Apps";
					}
					if (this.fundOptions === "Fund with Cash") {
						this.fundOptions = "Fund with Cash";
					}
					if (this.fundOptions === "Fund with Fluidcoins") {
						this.fundOptions = "Fund with Fluidcoins";
					}
					break;
				case "DedicatedVirtualAccountForm":
					this.fundForm = "DedicatedVirtualAccountForm";
					this.fundSubTitle =
						this.currency === "NGN"
							? "Make a direct transfer to this account to fund your wallet. Transactions may take 24 hours to complete."
							: "Make a direct transfer to this account to fund your wallet. Transactions may take 3-5 business days to complete.";
					// this.fundOptions = "Fund with Cash";
					break;
				case "Fund with Fintech Apps":
					this.fundForm = "SelectCurrencyForm";
					this.fundSubTitle = "Select your preferred currency.";
					this.fundOptions = "Fund with Fintech Apps";
					break;
				case "Fund with Fluidcoins":
					this.fundForm = "SelectCurrencyForm";
					this.fundSubTitle = "Select your preferred currency.";
					this.fundOptions = "Fund with Fluidcoins";
					break;
				case "fundAmountForm":
					if (this.entityCard.length === 0) {
						this.fundForm = "fundConfirmationForm";
						this.fundSubTitle = "Payment Confirmation";
					} else {
						this.fundForm = "CardOptions";
						this.fundSubTitle = "Select Debit Card";
					}
					break;
				case "CardOptions":
					this.fundForm = "fundConfirmationForm";
					this.fundSubTitle = "Payment Confirmation";
					break;
				case "storedCard":
					this.fundForm = "fundConfirmationForm";
					this.fundSubTitle = "Payment Confirmation";
					this.fundOptions = "Fund with stored card";
					break;
				case "newCard":
					this.fundForm = "fundConfirmationForm";
					this.fundSubTitle = "Payment Confirmation";
					this.fundOptions = "Fund with Cash";
					break;

				default:
					break;
			}
		},

		async finalizeBankConnection(type, data) {
			this.setLoading(true);
			this.showWithdrawModal = true;
			setTimeout(() => {
				this.$router.push({ name: "Wallet" });
				this.switchWithdrawForm("amountForm");
			}, 100);
			try {
				const response = await addBankApi.finalizeBankConnection(type, data);
				await this.fetchBankAccounts();
				const { message } = response.data;
				this.showToast({
					description: message,
					display: true,
					type: "success",
				});
				this.$router.push({ name: "Wallet" });
				this.setLoading(false);
			} catch (error) {
				responseHandler.handleError(error);
				this.setLoading(false);
			}
		},

		async calculateFees() {
			const currency = getItem("withdrawalPayload").currency;
			const amount = getItem("withdrawalPayload").amount;
			let fee;
			let percentFee;
			let minFee;
			const response = await api.withdrawFees();
			const { data } = response.data;
			const keys = Object.keys(data);
			keys.forEach((key, index) => {
				if (key === currency) {
					fee = data[key];
					switch (fee.includes("%")) {
						case true:
							if (this.withdrawMethod === "Trading Wallet") {
								this.withdrawFees = 0;
								return;
							}
							percentFee = Number((fee.split("%")[0] / 100) * amount);
							minFee = Number(fee.split(" ")[2].slice(1));
							minFee > percentFee
								? (this.withdrawFees = minFee)
								: (this.withdrawFees = percentFee);
							break;
						case false:
							this.withdrawFees = Number(fee);
							break;

						default:
							break;
					}
				}
			});
		},

		closeModal(modal) {
			switch (modal) {
				case "Withdraw Cash":
					this.showWithdrawModal = !this.showWithdrawModal;
					removeItem("withdrawalPayload");
					break;
				case "Fund Wallet":
					this.showFundModal = !this.showFundModal;
					removeItem("fundPayload");
					break;

				default:
					break;
			}
		},

		refresh(modal) {
			switch (modal) {
				case "Fund Wallet":
					this.fundForm = "fundOptionsForm";
					this.fundSubTitle = "How do you want to fund your wallet";
					break;
			}
		},

		gotoPage(page) {
			this.getWithdrawalDetails(page);
		},
	},
};
</script>

<style lang="scss" scoped>
#wallet {
	width: 100%;
	overflow: hidden;

	.container {
		@include fadeIn;

		@media screen and (min-width: 1024px) {
			max-width: 1280px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.table-container {
		overflow: auto;
	}

	.message {
		max-width: 250px;
	}
}
</style>
