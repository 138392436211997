<template>
	<div
		class="tw-w-full lg:tw-flex tw-flex-wrap tw-justify-between tw-items-center tw-rounded-md tw-bg-white tw-p-4 md:tw-px-5 md:tw-py-[28px] tw-mb-6 md:tw-mb-0"
	>
		<div class="">
			<div class="tw-flex tw-gap-2 tw-items-center tw-mb-2">
        <CurrencyFlag :currency="wallet.currency" />
				<p class="tw-text-purple-light tw-leading-[24px]">{{ wallet.currency }}</p>
			</div>
			<p class="tw-font-medium tw-text-2xl tw-leading-[38px] tw-text-2xl">
				{{ formatAmountToDollar(wallet.balance, 2, wallet.currency) }}
			</p>
		</div>
		<div class="tw-mt-5 xl:tw-flex tw-gap-6 tw-w-full">
			<Btn
				class="tw-w-full tw-mb-2 xl:tw-mb-0"
				title="+ Add money"
				@click="setCurrency('fund')"
			/>
      <button class="tw-w-full tw-rounded-[4px] tw-bg-pastel3 tw-px-4 tw-text-secondary tw-font-medium tw-text-sm tw-py-[10px]" v-if="wallet.balance > 0" @click="setCurrency('withdraw')">Withdraw</button>
		</div>
	</div>
</template>

<script setup>
import Btn from "@/components/general/BtnComponent.vue";
import { formatAmountToDollar } from "@/utils/helpers.js";
import { computed } from "vue";
import { useStore } from "vuex";
import CurrencyFlag from "./CurrencyFlag.vue";

const store = useStore()

const props = defineProps({
	wallet: {
		type: Object,
		required: false,
	},
});

const wallet = computed(() => props.wallet)

const emit = defineEmits(['fund-wallet', 'withdraw'])

formatAmountToDollar;

const getCurrency = (currency) => {
	switch (currency) {
		case "value":
			break;

		default:
			break;
	}
};

const setCurrency = (actionType) => {
    store.dispatch('organisationModule/setCurrency', wallet.value.currency)
		if(actionType === 'fund') {
			emit('fund-wallet')
		} else {
			emit('withdraw')
		}
}
</script>

<style lang="scss" scoped></style>
