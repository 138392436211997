import { ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export function useFundNotification() {
    const store = useStore();
    const route = useRoute();
    const isSuccessful = ref(false);
    // TODO
    // abstract all funding methods confirmation logic to this file norigen, plaid, stitch etc
    const checkFundingStatus = async() => {
        const query = route.query;
        const transactionRef = ref(query.tx_ref);
        const status = ref(query.status);
        if (transactionRef.value) {
            if (status.value === "successful" || status.value === "completed") {
                store.dispatch("showToast", {
                    description: "Wallet funding successful.",
                    display: true,
                    type: "success",
                });
                await Promise.all([
                    store.dispatch("organisationModule/getWalletBalance"),
                    store.dispatch("organisationModule/getEntityCard"),
                ]);
                isSuccessful.value = true;
            } else if (status.value === "cancelled") {
                store.dispatch("showToast", {
                    description: "Wallet funding failed.",
                    display: true,
                    type: "error",
                });
            }
            // setTimeout(() => {
            //     window.location = window.location.origin + window.location.pathname;
            // }, 3000);
        }
    };

    return { checkFundingStatus, isSuccessful };
}